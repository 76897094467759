import axios from 'axios';

export interface AppPacks {
  latest: string;
  windows?: AppPackInfo;
  linux?: AppPackInfo;
  mac?: AppPackInfo;
}

export interface AppPackInfo {
  url: string;
  version: string;
  arch: string;
}

export async function getAppPacks(): Promise<AppPacks> {
  const latest = await getLatestDownloadVersion();
  const vPacks = await getVersionPacks(latest);

  const appPacks: AppPacks = { latest };

  if (vPacks.linux && vPacks.linux.x64 && vPacks.linux.x64.deb) {
    appPacks.linux = {
      arch: 'x64',
      version: latest,
      url: `../downloads/app/${latest}/${vPacks.linux.x64.deb}`,
    };
  }

  if (vPacks.win && vPacks.win.x64 && vPacks.win.x64.exe) {
    appPacks.windows = {
      arch: 'x64',
      version: latest,
      url: `../downloads/app/${latest}/${vPacks.win.x64.exe}`,
    };
  }

  if (vPacks.mac && vPacks.mac.x64 && vPacks.mac.x64.dmg) {
    appPacks.mac = {
      arch: 'x64',
      version: latest,
      url: `../downloads/app/${latest}/${vPacks.mac.x64.dmg}`,
    };
  }

  return appPacks;
}

async function getLatestDownloadVersion(): Promise<string> {
  const reply = await axios.get('./downloads/app/latest');
  if (reply.status !== 200) {
    throw new Error(`Can't get latest download version: server replied with ${reply.status} to request of ${reply.config.url}`);
  }
  return reply.data;
}

interface VersionPacks {
  linux?: {
    x64?: LinuxPacks;
    ia32?: LinuxPacks;
  };
  mac?: {
    x64?: MacPacks;
  };
  win?: {
    x64?: WindowsPacks;
    ia32?: WindowsPacks;
  };
}

interface LinuxPacks {
  'deb'?: string;
  'tar.gz'?: string;
  'AppImage'?: string;
}

interface MacPacks {
  'dmg'?: string;
  'tar.gz'?: string;
}

interface WindowsPacks {
  'exe'?: string;
  'zip'?: string;
}

async function getVersionPacks(version: string): Promise<VersionPacks> {
  const reply = await axios.get(`./downloads/app/${version}/list`);
  if (reply.status !== 200) {
    throw new Error(`Can't get ${version} download list: server replied with ${reply.status} to request of ${reply.config.url}`);
  }
  return reply.data;
}
