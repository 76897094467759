import { Component, Vue } from 'vue-property-decorator';
import VueYoutube from 'vue-youtube';
import { isMobile } from '../../common/helpers';

@Component({})
export default class Principles extends Vue {
  public mobile: boolean = false;

  constructor() {
    super();
    Vue.use(VueYoutube);
  }

  public mounted(): void {
    this.mobile = isMobile();
  }
}
