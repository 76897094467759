import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/common/event-bus';
import { isMobile } from '../../common/helpers';

@Component({})
export default class Headline extends Vue {
  public mobile: boolean = false;

  constructor() {
    super();
  }

  public mounted(): void {
    this.mobile = isMobile();
  }

  public openDownloadModal(): void {
    EventBus.$emit('open-download-modal', true);
  }
}
