import { Component, Vue } from 'vue-property-decorator';
import { isMobile } from '../../common/helpers';

@Component({})
export default class Apps extends Vue {
  public mobile: boolean = false;

  constructor() {
    super();
  }

  public mounted(): void {
    this.mobile = isMobile();
  }
}
