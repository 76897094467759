import Vue from 'vue';
import App from './components/app/App.vue';

Vue.config.productionTip = false;
// Vue.prototype.openEarlyAccessPage = () => window.open(
//   'https://shop.privacysafe.tech/product/3nweb-early-access/', '_blank'
// );
Vue.prototype.openDownloadsPage = () => window.open(
  'https://download.privacysafe.app/', '_blank',
);

new Vue({
  render: (h) => h(App),
}).$mount('#app');
