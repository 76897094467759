/* tslint:disable:no-console */
import { Component, Vue } from 'vue-property-decorator';
import { MenuItem } from '@types';
import { EventBus } from '@/common/event-bus';
import { isMobile } from '../../common/helpers';

const correctionFactor = 100;

@Component({})
export default class Menu extends Vue {
  public menuItems: MenuItem[];
  public activeItem: string = '';
  public mobile: boolean = false;
  public isOpen: boolean = true;
  public isMenuShow: boolean = true;
  private lastScrollTop: number = 0;

  constructor() {
    super();
    this.menuItems = [
      { title: 'Space', class: 'apps', disabled: false, top: 0 },
      { title: '3N Principle', class: 'principles', disabled: false, top: 0 },
      { title: 'Today', class: 'about', disabled: false, top: 0 },
    ];
  }

  public created() {
    window.addEventListener('scroll', this.onScroll);
  }

  public mounted(): void {
    this.mobile = isMobile();
    this.isOpen = !this.mobile;
    // console.log(this.mobile);

    const appElementCoords = (document.querySelector('#app') as HTMLElement).getBoundingClientRect();
    this.menuItems.forEach((item: MenuItem) => {
      const blockElement = (this.$parent.$refs[item.class] as any).$el as HTMLElement;
      const coords = blockElement.getBoundingClientRect();
      item.top = coords.top - appElementCoords.top - (item.class !== 'principles' ? correctionFactor : 0);
    });
  }

  public destroyed(): void {
    window.removeEventListener('scroll', this.onScroll);
  }

  public onScroll(): void {
    const scrolled = window.pageXOffset || document.documentElement.scrollTop;
    if (this.mobile) {
      this.isOpen = false;
    }
    this.activeItem = this.setActiveItem(scrolled);
    this.displayMenu(scrolled);
  }

  public selectMenuItem(item: string): void {
    this.activeItem = item;
    const blockElement = (this.$parent.$refs[item] as any).$el as HTMLElement;
    blockElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    if (this.mobile) {
      this.isOpen = false;
    }
  }

  public toggle(): void {
    this.isOpen = !this.isOpen;
  }

  public openDownloadModal(): void {
    EventBus.$emit('open-download-modal', true);
  }

  private setActiveItem(scrolled: number): string {
    let searchIndex = -1;
    const tmpMenuItems = this.menuItems.slice();
    tmpMenuItems.sort((a, b) => b.top - a.top);
    for (let i = (tmpMenuItems.length - 1); i >= 0; i--) {
      if (scrolled > tmpMenuItems[i].top) { searchIndex = i; }
    }
    return searchIndex > -1
      ? tmpMenuItems[searchIndex].class
      : 'main';
  }

  private displayMenu(scrolled: number): void {
    const menuHeight = this.mobile
      ? 64
      : Math.round(window.innerWidth * 0.052);
    console.log('H: ', menuHeight);
    this.isMenuShow = this.lastScrollTop > scrolled || scrolled <= menuHeight;
    this.lastScrollTop = scrolled;
    // console.log(scrolled);
  }
}

