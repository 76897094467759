import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/common/event-bus';
import { isMobile } from '@/common/helpers';
import { AppPacks, getAppPacks } from '@/common/downloads-api';

import Menu from '../menu/Menu.vue';
import Footer from '../footer/Footer.vue';
import Headline from '@/views/headline/Headline.vue';
import Apps from '@/views/apps/Apps.vue';
import Principles from '@/views/principles/Principles.vue';
import About from '@/views/about/About.vue';

@Component({
  components: {
    'app-menu': Menu,
    'app-footer': Footer,
    'app-headline': Headline,
    'app-apps': Apps,
    'app-principles': Principles,
    'app-about': About,
  },
})
export default class App extends Vue {
  public isModalOpen: boolean = false;
  public mobile: boolean = false;

  public appPacks: AppPacks|undefined = undefined;
  private promiseOfAppPacks: Promise<void>|undefined = undefined;

  constructor() {
    super();
  }

  public created(): void {
    this.mobile = isMobile();

    EventBus.$on('open-download-modal', (state: boolean) => {
      this.controlDownloadModal(state);
    });

    this.getAndUpdateAppPacks();

    if (location.hash === '#downloads') {
      this.getAndUpdateAppPacks()
      .then(() => {
        EventBus.$emit('open-download-modal', true);
      });
    }
  }

  public controlDownloadModal(mode: boolean): void {
    this.isModalOpen = mode;
  }

  private getAndUpdateAppPacks(): Promise<void> {
    if (!this.promiseOfAppPacks) {
      this.promiseOfAppPacks = getAppPacks()
      .then((appPacks) => {
        this.appPacks  = appPacks;
        this.promiseOfAppPacks = undefined;
      });
    }
    return this.promiseOfAppPacks;
  }
}
